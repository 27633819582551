<template>
  <div  class="ma-md-5 mb-16 px-0 " >

    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">账号安全</div>
    </div>

    <v-card class=" " flat tile>
      <div class="item-header pa-4">修改登录密码</div>
      <v-container fluid class="px-4 px-md-16">
        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="oldpwd"
              color=" darken-2"
              label="原密码"
              type="password"
              required
              clearable
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="newpwd"
              color=" darken-2"
              label="新的密码"
              type="password"
              required
              clearable
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="newpwd2"
              color=" darken-2"
              label="确认新密码"
              type="password"
              required
              clearable
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
          >
            <v-btn  dark outlined  tile class="main-btn addbtn" @click="save">保存密码</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-card>

   


  </div>
</template>

<script>




import userApi from '@/api/user'
export default {


  data: () => ({
    itemid : 0,
    info : {},
    oldpwd:"",
    newpwd:"",
    newpwd2:"",
    

  
    //
  }),
  computed: {


  },
  created() {

    
    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    save() {

      if (this.oldpwd=="" || this.newpwd=="" ||this.newpwd2=="") {
        this.$message.warning("请输入完整信息")
        return
      }
      if (this.newpwd!=this.newpwd2) {
        this.$message.warning("新密码确认不一致")
        return
      }

      if (this.newpwd.length<6) {
        this.$message.warning("请输入6位字母+数字混合密码")
        return
      }

      userApi.updatePassword({old_password:this.oldpwd, new_password:this.newpwd}).then(res => {
        
        if (res.data==1) {
          this.$message.success("修改密码成功")
        } else {
          this.$message.warning(res.message)
        }
      })

      
    }
  },
};
</script>
<style scoped>

.item-header {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}
.addbtn {
  width:50%;
}
</style>

